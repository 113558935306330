import React from 'react';

import Button from 'components/Buttons/NewButton';
import { isCoachingAppointment } from 'kb-shared/utilities/appointment.helper';

import { Container, Content } from './ConfirmationAppointmentDetails.styled';
import { Props } from './ConfirmationAppointmentDetails.types';
import { TermsDetails } from './TermsDetails';

export const ConfirmAppointmentDetails: React.FC<Props> = props => {
  const {
    appointment,
    loading,
    disabled,
    onTermsAcceptToggled,
    termsAccepted,
    isReschedule
  } = props;

  const buttonText = isReschedule ? 'CONFIRM & RESCHEDULE' : 'CONFIRM & BOOK';

  return (
    <Container>
      <Content>
        <TermsDetails
          selected={termsAccepted}
          onCheckBoxSelected={onTermsAcceptToggled}
          showSessionDisclaimerCharge={
            //@ts-ignore
            isCoachingAppointment(appointment) &&
            //@ts-ignore
            !appointment?.shouldChargeAtBooking &&
            //@ts-ignore
            appointment.rateCents !== 0
            // @ts-check
          }
        />
        <Button
          loading={loading}
          disabled={disabled || !termsAccepted || loading}
          type="submit"
          text={buttonText}
        />
      </Content>
    </Container>
  );
};
