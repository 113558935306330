import { ApolloError, useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Appointment,
  AppointmentProduct,
  AppointmentType,
  graphql,
  isAppointment,
  isAppointmentType,
  RescheduleAppointmentResponseData,
  TimeSlot,
  ValidPromo
} from 'kb-shared';
import { analytics } from 'utilities/analytics';
import { showErrorToast } from 'utilities/notificationUtils';
import { pageUrl } from 'utilities/pageUrl';

import { ConfirmDetails } from '../../../components/ConfirmDetails';
import { RESCHEDULE_APPOINTMENT } from '../Confirm.graphql';
import { generateErrorString } from '../Confirm.utils';

export const RescheduleAppointmentForm = ({
  product,
  timeSlot
}: {
  product: AppointmentProduct<AppointmentType> | null;
  timeSlot: TimeSlot | null;
}) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [paymentTermsChecked, setPaymentTermsChecked] = useState(false);
  const [validPromo, setValidPromo] = useState<ValidPromo | null>(null);

  const [rescheduleAppointment] = useMutation<
    RescheduleAppointmentResponseData,
    { appointmentId: number; newTimeSlotId: number }
  >(RESCHEDULE_APPOINTMENT, {
    refetchQueries: [{ query: graphql.query.PATIENT_APPOINTMENTS }]
  });

  const onError = (err: ApolloError | null) => {
    const errorString = generateErrorString(err);

    analytics.track(analytics.EVENTS.APPOINTMENT_BOOKING_FAILED, { error: errorString });

    setLoading(false);
    showErrorToast(errorString);
  };

  if (!product) return null;
  if (product?.type !== 'reschedule_appointment' || !timeSlot) return null;

  // Grab a reference to the appointment that is being rescheduled
  const appointment: Appointment = product.data;

  const onSubmit = () => {
    return rescheduleAppointment({
      variables: {
        appointmentId: parseInt(appointment.id, 10),
        newTimeSlotId: parseInt(timeSlot.id, 10)
      }
    })
      .then(() => history.push(pageUrl.dashboard()))
      .catch(onError);
  };

  if (!isAppointment(appointment) && !isAppointmentType(appointment)) return null;

  return (
    <form
      onSubmit={async e => {
        e.preventDefault();
        setLoading(true);
        onSubmit();
      }}
    >
      <ConfirmDetails
        appointment={appointment}
        product={product}
        loading={loading}
        paymentTermsChecked={paymentTermsChecked}
        validPromo={validPromo}
        onPaymentTermsChecked={checked => setPaymentTermsChecked(checked)}
        onValidPromoChange={validPromo => setValidPromo(validPromo)}
      />
    </form>
  );
};
