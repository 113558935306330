import React from 'react';

import {
  Appointment,
  AppointmentProduct,
  AppointmentType,
  isAppointment,
  isAppointmentType,
  Membership,
  ValidPromo
} from 'kb-shared';

import { AppointmentSummaryDetails } from './AppointmentSummaryDetails';
import { ConfirmMembershipDetails } from './ConfirmMembershipDetails';

interface Props {
  appointment: Appointment | AppointmentType | Membership;
  product: AppointmentProduct<AppointmentType>;
  loading?: boolean;
  disabled?: boolean;
  paymentTermsChecked?: boolean;
  validPromo: ValidPromo | null;
  hasEmployer?: boolean;
  onPaymentTermsChecked: (checked: boolean) => void;
  onValidPromoChange: (validPromo: ValidPromo | null) => void;
}

export const ConfirmDetails = (props: Props) => {
  const {
    product,
    loading = false,
    disabled = false,
    paymentTermsChecked = false,
    onPaymentTermsChecked,
    onValidPromoChange,
    validPromo,
    hasEmployer = false
  } = props;

  if (isAppointmentType(product.data) || isAppointment(product.data)) {
    return (
      <AppointmentSummaryDetails
        appointment={product.data}
        product={product}
        isReschedule={product.type === 'reschedule_appointment'}
        loading={loading}
        disabled={disabled}
        paymentTermsChecked={paymentTermsChecked}
        onPaymentTermsChecked={onPaymentTermsChecked}
        onValidPromoChange={onValidPromoChange}
      />
    );
  } else if (product.type === 'membership') {
    return (
      <ConfirmMembershipDetails
        membership={product.data}
        loading={loading}
        termsAccepted={paymentTermsChecked}
        discountPrice={validPromo && validPromo.totalCents}
        hasEmployer={hasEmployer}
        validatedPromoCode={validPromo !== null}
        onTermsAcceptToggled={() => onPaymentTermsChecked(true)}
      />
    );
  } else {
    return null;
  }
};
